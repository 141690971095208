<template>
	<div class="main-content">
		<div>
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header flex gap-2 flex-col">
              <div class="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                <div class="input-group input-group-flush d-flex flex-row-reverse flex-grow" >
                  <input
                    v-model.trim="search"
                    class="form-control list-search"
                    type="search"
                    placeholder="Search"
                    @keyup.enter.prevent="fetchPartners(true)"
                    />
                  <span class="input-group-text border-0">
                    <i
                      class="fe fe-search pointer"
                      @click.prevent="fetchPartners(true)"
                      ></i>
                  </span>
                </div>
                <date-picker :range="true" class="shrink-0 sm:max-w-[220px]" v-model="date" valueType="format" placeholder="Filter by created date"></date-picker>
              </div>
						</div>
						<div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
							<div class="p-3">
								<label for="status_active" class="d-block-inline px-2">
									<input
										type="radio"
										v-model="statusFilter"
										name="status"
										value="active"
										id="status_active"
										/>
									Active
								</label>
								<label for="status_inactive" class="d-block-inline px-2">
									<input
										type="radio"
										v-model="statusFilter"
										name="status"
										value="inactive"
										id="status_inactive"
										/>
									Inactive
								</label>
							</div>
              <DownloadButton class="!shrink-0" :downloading="downloadingReport" label="Download Report" @download="downloadReport()" />
						</div>

						<b-table
							striped
							hover
							selectable
							show-empty
							responsive
							:items="partners"
							:fields="fields"
							:current-page="currentPage"
							:busy="fetchingPartners"
							@row-clicked="showPartnerDetails"
							>
							<template #table-busy>
								<div
									class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
									>
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>

							<!-- <template #empty>
                <p class="text-center text-secondary">{{search ? `No records found for search value: "${search}"` : 'No records available'}}</p>
              </template> -->

							<template #empty>
								<p class="text-center text-secondary">No records available</p>
							</template>

							<template #cell(status)="data">
								<span
									class="text-white"
									:class="`badge p-2 ${
										data.item.status === 'active'
											? 'badge-success'
											: data.item.status === 'suspended'
												? 'badge-danger'
												: data.item.status === 'pending'
													? 'badge-warning'
													: ''
									}`"
									>
									{{ data.item.status }}
								</span>
							</template>
							<template #cell(serialNumber)="data">
								<p>
									{{ data.index + 1 }}
								</p>
							</template>
							<template #cell(name)="data">
								<p>
									{{ `${data.item.owner?.fname} ${data.item.owner?.lname}` }}
								</p>
							</template>

							<template #cell(email)="data">
								<p>
									{{ `${data?.item?.owner?.email ?? 'N/A'}` }}
								</p>
							</template>
							<template #cell(vehicle_count)="data">
								<span class="“text-center”">{{
									data.item.vehicles_count
								}}</span>
							</template>
							<template #cell(created_at)="data">
								{{ data.value | date('MMMM d, yyyy') }}
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-dark font-weight-bold">{{
										tablePageText
									}}</span>
								</div>
								<div class="col-auto">
									<b-pagination
										v-model="currentPage"
										:total-rows="totalRecords"
										:per-page="pageSize"
										></b-pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TableView from '@/mixins/tableview'
import DownloadButton from '@/components/core/DownloadButton.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import Papa from 'papaparse'
import { axiosInstance as axios } from '@/plugins/axios.js'
import { downloadFile } from '@/composables/utils'
import DatePicker from 'vue2-datepicker'
import {addToQuery, removeQueryParam} from '@/composables/utils'
import router from '@/router'

export default {
  mixins: [TableView],
  data () {
    return {
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'name',
          label: 'Name'
        },
        // {
        //   key: ‘company_name’,
        //   label: ‘Company Name’,
        // },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'vehicles_count',
          label: 'Number of Vehicles'
        },
        {
          key: 'created_at',
          label: 'Date Created'
        },
        {
          key: 'status',
          label: 'Status'
        }

      ],
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      debounce: null,
      partners: [],
      fetchingPartners: true,
      downloadingReport : false,
      search: '',
      statusFilter: 'active',
      date: []
    }
  },
  components: {DownloadButton, DatePicker},
  computed: {
  },
  watch: {
    currentPage () {
      this.fetchPartners()
    },
    search () {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if(this.search) {
          addToQuery(this.$route, router, {search: this.search})
        } else {
          removeQueryParam(this.$route, router, ['search'])
        }
        this.fetchPartners(true)
      }, 600)
    },
    date () {
      if(this.date[0] && this.date[1]) {
        addToQuery(this.$route, router, {from: this.date[0],to: this.date[1]})
      } else {
        removeQueryParam(this.$route, router, ['from','to'])
      }
      this.fetchPartners(true)
    },
    statusFilter () {
      this.fetchPartners(true)
    }
  },
  methods: {
    fetchPartners (reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingPartners = true
      const url = `/v1/partners?metadata=true&limit=${this.pageSize}&page=${this.currentPage}&status=${this.statusFilter}&related=owner${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`
      const request = this.axios.get(url)
      request
        .then((res) => {
          this.partners = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.partners
        })
        .finally(() => (this.fetchingPartners = false))
    },
    showPartnerDetails (data) {
      this.$router.push({
        name: 'PartnerDetails',
        params: {
          vehicleCount: data?.vehicles_count,
          partnerId: data?.id,
          accountSid: data?.account_sid
        }
      })
    },
    async downloadReport() {
      this.downloadingReport = true
      try {
        const res = await axios.get(`/v1/partners?metadata=true&limit=${this.pageSize}&page=1&status=${this.statusFilter}&related=owner${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`)
        if(res.data?.data?.length){
          const total = res.data?.metadata?.total
          const resp = await axios.get(`/v1/partners?metadata=true&limit=${total}&page=1&status=${this.statusFilter}&related=owner${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`)
          const x = resp.data?.data
          const newArr = []
          for (const el of x) {
            const y = {
              Name: `${el?.owner?.fname || '' } ${el?.owner?.lname || ''}`,
              Email: el?.owner?.email || 'N/A',
              Number_of_vehicles: el?.vehicles_count || 'N/A',
              Date_created: moment(el?.created_at).format('MMMM Do, YYYY') || 'N/A',
              Status: el?.status,
            }
            newArr.push(y)
          }

          const csv = Papa.unparse(newArr)
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
          const url = URL.createObjectURL(blob)
          downloadFile(url, `all-${this.statusFilter}-partners`)
          this.downloadingReport = false
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: 'No data to download'})
          this.downloadingReport = false
        }
      } catch (err) {
        Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message || 'No data to download'})
        this.downloadingReport = false
      }
    },
    checkForFilters() {
      const q = this.$route.query
      if (q.search) this.search = q.search
      if(q.from && q.to){
        this.date = [q.from, q.to]
      }
      this.fetchPartners()
    }
  },
  mounted() {
    this.checkForFilters()
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
</style>
